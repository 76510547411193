import React from "react";
import Reflux from "reflux";
import {Avatar, Layout, Menu} from "antd";
import {UserOutlined, LogoutOutlined} from '@ant-design/icons';
import GUtils from "@/utils";
import MenuIcon from "@/utils/menu";
import {Link} from "react-router-dom";

import {createAction} from "./Action";
import Store from "./Store";
import "./style.less";

const {Header, Content, Footer, Sider} = Layout;

export default class HomePage extends Reflux.Component {

    constructor(props) {
        super(props);
        this.action = createAction()
        this.store = new Store(props, this.action);
    }

    componentDidMount() {
        let path = window.location.pathname;
        if(path.startsWith("/page")){ //在内部页面了
            let user = JSON.parse(localStorage.getItem("user"));
            if (!user) {
                window.RouterGo(window.CONFIG.PAGE_LOGIN);
                return;
            }
            GUtils.initUserInfo(user);
            this.action.getMenus();
            this.setState({user});
            // 检查是否在菜单列表中
        }else if(path.startsWith("/login")){

        }else{

            window.RouterGo("/403");
        }

        GUtils.on(window, "login-success", () => {
            this.setState({user:window.user});
            this.action.getMenus();
        })
    }

    handleCollapse(collapsed) {
        this.setState({collapsed})
    }


    renderMenuItem(m, level=1) {
        let {code, name, path, subMenus} = m;
        let Icon = MenuIcon[code]
        if (subMenus) {

            return (
                <Menu.SubMenu className={"menu-"+level} key={code} title={name} icon={Icon}>
                    {subMenus.map(sub => this.renderMenuItem(sub, level+1 ))}
                </Menu.SubMenu>
            )
        }
        return <Menu.Item className={"menu-"+level} key={code} icon={Icon}>
            <Link to={"/page" + path}>{name}</Link>
        </Menu.Item>
    }

    buildMenus(menus=[], level=1) {

        menus = menus.map(m=>{
            let {code, name, path, subMenus} = m;
            let Icon = MenuIcon[code];
            return {
                icon:Icon,
                key:"/page"+path,
                label:name,
                children: subMenus && this.buildMenus(subMenus)
            }
        })
        return menus;

    }
    handleMenuChange(k){
        console.log(k);
    }
    render() {
        let {children} = this.props;
        let path = window.location.pathname;
        let isPage = path.startsWith("/page");
        return isPage ? this.renderMenuPage() : this.renderSimpleView();
    }

    // 基本页面
    renderSimpleView() {
        let {children} = this.props;
        return <Layout style={{minHeight: '100vh'}}>
            <Content>
                {children}
            </Content>
        </Layout>
    }

    handleLogout(){
        GUtils.confirm("退出","确认退出系统?",this.action.logout);
    }
    renderMenuPage() {

        let {collapsed, menus = [], user = {}} = this.state;
        let {children} = this.props;
        let {userName, scope} = user;
        //window.RouteHistory = this.context.router.history
        return <Layout style={{minHeight: '100vh'}} className="layout">
            <Sider collapsible collapsed={collapsed} onCollapse={this.handleCollapse.bind(this)}>
                <div className="logo"/>
                <div className="avatar flex-between">
                    <Avatar size={60} icon={<UserOutlined/>}/>
                    {
                        collapsed
                        ||
                        <div className="avatar-name">
                            <div className="name">
                                <Link to={window.CONFIG.PAGE_PERSONAL_RESETPWD}>{userName}</Link>
                            </div>
                            <div className="scope">{window.ENUMS.Scope[scope]}</div>
                            <div className="logout-btn" onClick={this.handleLogout.bind(this)}><LogoutOutlined />退出</div>
                        </div>
                    }


                </div>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" onClick={this.handleMenuChange.bind(this)}>
                    {
                        menus.map(o => this.renderMenuItem(o))
                    }
                </Menu>
            </Sider>
            <Layout>
                <Header/>
                <Content style={{margin: '0 16px'}}>
                    {menus && children}
                </Content>
            </Layout>
        </Layout>
    }

}