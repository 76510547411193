import {
    BankOutlined,
    GoldOutlined,
    AccountBookOutlined,
    UserOutlined,
    ApiOutlined,
    ApartmentOutlined,
    AppstoreOutlined,
    ContainerOutlined
} from '@ant-design/icons';
import React from "react";

const MenuIcon = {
    shop: <BankOutlined/>,
    product: <GoldOutlined />,
    order: <ContainerOutlined/>,
    billing: <AccountBookOutlined/>,
    user: <UserOutlined/>,
    account: <ApartmentOutlined/>,
    resource: <BankOutlined/>,
    device: <ApiOutlined/>,
    system: <AppstoreOutlined/>
}

export default MenuIcon;