import Reflux from "reflux";
import {Message} from "@biz/Components";
import MenuHelper from "./biz/MenuHelper";
import GUtils from "@/utils/auth";

export default class Store extends Reflux.Store {
    constructor(props, action) {
        super(props);
        this.state = this.getInitialState();
        this.listenables = action;
        this.props = props;
        this.action = action;
    }

    getInitialState() {
        return {
            menus: [],
            collapsed:false
        };
    }

    onGetMenus() {
        this.setState({loading: true})
    }

    onGetMenusCompleted(res) {
        this.setState({loading: false})
        if (res.success) {
            let menus = MenuHelper.initMenus(res.voList);

            window.user.MENUS = menus;
            window.user.menus = res.voList;
            GUtils.initAllAuth();
            this.setState({menus});
        } else {
            Message.error(res.errMsg);
            this.setState({errMsg: res.errMsg});
        }
    }

    onLogout(){
        this.setState({loading: true})
    }
    onLogoutCompleted(res){
        this.setState({loading: false})
        Message.success("退出成功");
        window.user = null;
        window.RouterGo(window.CONFIG.PAGE_LOGIN);
    }
}