const GEEK_HOST = "http://manage.iot.whut-smart.com/"
const  goUrl = (url)=> window.open(url);


export default {
    getQueryString(name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let queryStr = location.href;
        queryStr = queryStr.substring(queryStr.indexOf('?') + 1, queryStr.length);
        let r = queryStr.match(reg);
        if (r != null) return (r[2]);
        return null;
    },

    getUrlPath() {
        let path = window.location.href;
        path = path.match("/page([^?]*)")[1];
        let m = path.match("(.*?)/detail/([^?]*)");
        if(m){
            return [m[1], m[2]];
        }

        return [path,""];
    },



    // 跳转到Geek官网页面
    goGeekWeb(){
        goUrl(GEEK_HOST);
    },
    // 跳转到Geek官设备详情网页面
    goGeekDetail(id){
       goUrl(GEEK_HOST+"#/device/detail?id="+id);
    }
}