import moment from "moment";

export default {
    dateTimeFormatter: function (value, format) {
        return this.toFormat(value,format || 'YYYY-MM-DD HH:mm:ss');
    },
    dateFormatter: function (value, format) {
        return this.toFormat(value,format || 'YYYY-MM-DD');
    },
    timeFormatter: function (value, format) {
        return this.toFormat(value,format || 'HH:mm:ss');

    },
    toFormat(value, format){
        if (value) {
            return moment(value).format(format);// +" "+moment(value).format(format);
        } else {
            return '';
        }
    },

    dealDateRange(dateRange, field, so){
        if(dateRange && dateRange.length>0){
            let from = dateRange[0];
            let to = dateRange[1];
            if(from){
                from =moment([from.year(), from.month(), from.date()])
                //from = from.add(-1* zone,"hour");
            }
            to =moment([to.year(), to.month(), to.date(),23,59,59])
            //to = to.add(-1* zone,"hour");

            so[field+"From"] =from;// Number(dateRange[0].startOf('day'));
            so[field+"To"] =to;// Number(dateRange[1].endOf('day'))
        }else{
            so[field+"From"] = null;
            so[field+"To"] = null;
        }
    },
}