let menuAuth = []
let btnAuth = []
const AuthHelper = {
    // 是否有权限，目前主要针对菜单级别
    hasAuth(menu) {
        return menuAuth.includes(menu)
    },
    hasAnyAuth(...menus) {
        return menus.some(o => this.hasAuth(o))
    },
    hasAllAuth(...menus) {
        return menus.every(o => this.hasAuth(o))
    },

    // 按钮级别的权限，操作权限
    hasBtnAuth(btn) {
        return btnAuth.includes(btn)
    },
    hasAnyBtnAuth(...btns) {
        return btns.every(o => this.hasBtnAuth(o))
    },
    hasAllBtnAuth(...btns) {
        return btns.every(o => this.hasBtnAuth(o))
    },
    initAllAuth() {
        if (window.user && (window.user.menus)) {
            // 菜单
            menuAuth = []
            let list = window.user.menus || []
            list.forEach(o => {
                menuAuth.push(o.code)
            })

            // 按钮
            btnAuth = []
           /* list = window.user.webButtons || []
            list.forEach(o => {
                btnAuth.push(o.path)
            })*/
        }

    },

    AUTH: {

    },


}


export default AuthHelper
