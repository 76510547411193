import React from 'react';
import HOC from './Base';

class _404_ extends React.Component {
    constructor (props) {
        super(props);
    }
    render () {
        return (
            <div>
                <div className="status">404</div>
                <div style={{fontSize: 30}}>貌似您的页面已经逃离地球</div>
                <div style={{fontSize: 14}}>我们正在努力恢复，请稍后再试！<span style={{color: '#f00'}}>{this.props.count}</span> 秒后返回首页...</div>
            </div>
        );
    }
}

export default HOC(_404_, {
    count: 3
});
