import React from 'react';
import './style.less';

const Base = (Wrapped, opts = {}) => {
    const {count, ...rest} = opts;
    class Wrapper extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                count: count
            };
            this.countdown = this.countdown.bind(this);
            this.backToHome = this.backToHome.bind(this);
        }
        componentDidMount () {
            this.state.count != undefined && (this.task = setInterval(this.countdown, 1000));
        }
        countdown () {
            const _count = this.state.count - 1;
            if (_count === 0) {
                clearInterval(this.task);
                this.backToHome();
            } else {
                this.setState({
                    count: _count
                });
            }
        }
        backToHome () {
            window.RouterGo(window.CONFIG.PAGE_HOME);
        }
        render () {
            return (
                <div className="mod-error-page">
                    <Wrapped {...this.state} {...rest} />
                    <button className="btn-back" onClick={this.backToHome}>返回首页</button>
                </div>
            );
        }
    }
    return Wrapper;
};

export default Base;
