import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";


import './index.less';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
//  <React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    // </React.StrictMode>
);


reportWebVitals();
