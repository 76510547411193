export default {
    PAGE_HOME:"/page/home",
    PAGE_LOGIN:"/login",
    PAGE_SHOP:"/page/shop/list",
    PAGE_DETAIL:"/detail/view",

    PAGE_RECHARGE_PRODUCT: "/page/product/recharge",
    PAGE_RECHARGE_ORDER: "/page/order/recharge",
    PAGE_WASH_ORDER: "/page/order/wash",

    PAGE_VIP_PRODUCT: "/page/product/vip",
    PAGE_VIP_ORDER: "/page/order/vip",

    PAGE_USER_ACCOUNT: "/page/user/userAccount",
    PAGE_USER_BALANCE: "/page/user/userBalance",
    PAGE_DEVICE: "/page/device/internal",

    PAGE_PERSONAL_RESETPWD: "/page/personal/resetPwd",


    REG_ :/^[0-9]+([.]{1}[0-9]+){0,1}$/,
    //REG_PHONE:/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/,
    REG_PHONE:/^1[3456789]\d{9}$/,

    REG_NUM_D2: /^\d+(\.)?\d{0,2}$/, // 两位小数 正
    REG_NUM_D2_2: /^(\-)?\d*(\.)?\d{0,2}$/,// 两位小数 正负
    REG_NUM_D2_NEGATIVE: /^(\-)\d*(\.)?\d{0,2}$/, // 负
    REG_NUM_INT: /^\d+$/,
    REG_NUM_INT_2: /^(\-)?\d+$/,

    REG_NUM_1:/^(?:0\.\d{0,2}|[01](?:\.0)?)$/, // 小于等于1的小数

    PWD_CHARS:"",
    REG_PWD: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[@$!%*#?&+=])[a-zA-Z\d@$!%*#?&+=]*$/,



    /**
     * 获取翻页控件属性的公共方法
     * @param  {[type]} Actions         [description]
     * @param  {Number} pageSizeDefault [description]
     * @return {[type]}                 [description]
     */
    getPagerConfig: (Actions, pageSizeDefault = 20, pageSizeOptions) => {
        if (!pageSizeOptions) {
            pageSizeOptions = window.isRoot
                ? ["20", "50", "100", "500"]
                : ["20", "50", "100"]
        }

        return {
            PAGE_SIZE: pageSizeDefault, // storage.getJsonItem('pageSizeCache', curRoute) || pageSizeDefault,
            defaultSo: { pageNumber: 1, pageSize: pageSizeDefault },
            pagination: {
                position:["bottomCenter"],
               // size: "small",
                showSizeChanger: true,
                pageSizeOptions: pageSizeOptions,
                showTotal(total) {
                    return (
                        <div className="col3f536a">
                            <span>共 {total} 条</span>
                        </div>
                    )
                },
                onShowSizeChange(current, pageSize) {
                    Actions.changePageSize(pageSize)
                    Actions.changeCurrent(current)
                },
                onChange(current) {
                    Actions.changeCurrent(current)
                },
                itemRender: (_, type, originalElement) => {
                    if (type === 'prev') {
                        return <a>上一页</a>;
                    }
                    if (type === 'next') {
                        return <a>下一页</a>;
                    }
                    return originalElement;
                }
            },
        }
    },
}