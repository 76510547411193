export default {
    // 将菜单列表构造成菜单树
    initMenus(menus){
        if(!menus){
            return [];
        }
        let list =[]; //一级菜单
        let menuIdMap = {}; //id-menu
        let menuPathNameMap ={};
        menus.forEach(o=>{
            let {id, parentId,path} = o;
            menuIdMap[id] = o;
            if(!parentId) {
                list.push(o);
            }
            menuPathNameMap[path]=o;
        });
        window.MENU_PATH_MAP=menuPathNameMap;
        menus.forEach(o=>{
            let {id, parentId} = o;
            if(parentId){ //只处理子菜单
                let parentMenu = menuIdMap[parentId];
                if(parentMenu){//父级菜单不存在，忽略
                    if(!parentMenu.subMenus){
                        parentMenu.subMenus=[o]
                    }else{
                        parentMenu.subMenus.push(o)
                    }
                }
            }

        })
        return this.buildMenus(list);
    },
    buildMenus(list){
        if(!list){
            return null;
        }
        return list.map(m=>{
            let {code,name,path,subMenus} = m;
            return {
                code,name,path,
                subMenus:this.buildMenus(subMenus)

            }
        })
    }

}