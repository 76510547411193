import {message, ConfigProvider} from 'antd';
import {Message} from "./biz/Components";
import Routes from "./routes";
import {useNavigate, useRoutes} from "react-router";

import zh_CN from 'antd/locale/zh_CN';

import 'antd/dist/reset.css';
import './App.css';
import "./common.less"
import "./mixins.less"
import HomePage from "./pages/home";


window.CONFIG = require('@/utils/config').default;
window.ENUMS = require('@/constants/enums').default;

function App() {
    const [messageApi, contextHolder] = message.useMessage();
    Message.init(messageApi);

    // 方便路由跳转
    window.RouterGo=useNavigate();
    return (
        <div className="App">
            {contextHolder}
            <ConfigProvider locale={zh_CN}>
            <HomePage>
                {useRoutes(Routes)}
            </HomePage>
            </ConfigProvider>
        </div>
    );
}

export default App;
