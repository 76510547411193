import React from "react";
import {Spin} from "antd";

const LoginPage =  React.lazy(() => import('@/pages/login'));
const HomePage =  React.lazy(() => import( "@/pages/home"));

const ShopPage =  React.lazy(() => import( "@/pages/shop/shop"));
const ShopDetail =  React.lazy(() => import( "@/pages/shop/shop/biz/Detail"));
const ShopStationPage =  React.lazy(() => import( "@/pages/shop/station"));
const ShopStationDetail =  React.lazy(() => import( "@/pages/shop/station/biz/Detail"));





const RechargeProductPage =  React.lazy(() => import( "@/pages/product/rechargeProduct"));
const RechargeProductDetail =  React.lazy(() => import( "@/pages/product/rechargeProduct/biz/Detail"));
const WashProductPage =  React.lazy(() => import( "@/pages/product/washProduct"));
const WashProductDetail =  React.lazy(() => import( "@/pages/product/washProduct/biz/Detail"));
const VipProductPage =  React.lazy(() => import( "@/pages/product/vipProduct"));
const VipProductDetail =  React.lazy(() => import( "@/pages/product/vipProduct/biz/Detail"));


const ShopAccountPage =  React.lazy(() => import( "@/pages/account/shopAccount"));
const ShopAccountDetail =  React.lazy(() => import( "@/pages/account/shopAccount/biz/Detail"));
const UserAccountPage =  React.lazy(() => import( "@/pages/account/userAccount"));
const UserAccountDetail =  React.lazy(() => import( "@/pages/account/userAccount/biz/Detail"));
const UserBalancePage =  React.lazy(() => import( "@/pages/account/userBalance"));
const UserBalanceDetail =  React.lazy(() => import( "@/pages/account/userBalance/biz/Detail"));



const RechargeOrderPage =  React.lazy(() => import( "@/pages/order/rechargeOrder"));
const RechargeOrderDetail =  React.lazy(() => import( "@/pages/order/rechargeOrder/biz/Detail"));
const WashOrderPage =  React.lazy(() => import( "@/pages/order/washOrder"));
const WashOrderDetail =  React.lazy(() => import( "@/pages/order/washOrder/biz/Detail"));
const VipOrderPage =  React.lazy(() => import( "@/pages/order/vipOrder"));
const VipOrderDetail =  React.lazy(() => import( "@/pages/order/vipOrder/biz/Detail"));


const DocumentPage =  React.lazy(() => import( "@/pages/resource/document"));
const DocumentDetail =  React.lazy(() => import( "@/pages/resource/document/biz/Detail"));
const AdvertisementPage =  React.lazy(() => import( "@/pages/resource/advertisement"));
const AdvertisementDetail =  React.lazy(() => import( "@/pages/resource/advertisement/biz/Detail"));

const ShopBalancePage =  React.lazy(() => import( "@/pages/billing/shopBalance"));
const ShopBalanceDetail =  React.lazy(() => import( "@/pages/billing/shopBalance/biz/Detail"));
const ShopWithdrawalPage =  React.lazy(() => import( "@/pages/billing/shopWithdrawal"));
const ShopBalanceDetailPage =  React.lazy(() => import( "@/pages/billing/shopBalanceDetail"));




const InternalDevicePage =  React.lazy(() => import( "@/pages/device/internalDevice"));
const InternalDeviceDetail =  React.lazy(() => import( "@/pages/device/internalDevice/biz/Detail"));
const GeekDevicePage =  React.lazy(() => import( "@/pages/device/geekDevice"));
const GeekDeviceDetail =  React.lazy(() => import( "@/pages/device/geekDevice/biz/Detail"));

const MenuPage =  React.lazy(() => import( "@/pages/system/menu"));
const MenuDetail =  React.lazy(() => import( "@/pages/system/menu/biz/Detail"));
const SuperPage =  React.lazy(() => import( "@/pages/system/super"));
const UserSuperPage =  React.lazy(() => import( "@/pages/system/userSuper"));

const ConfigPage =  React.lazy(() => import( "@/pages/system/config"));
const ConfigDetail =  React.lazy(() => import( "@/pages/system/config/biz/Detail"));


const PersonalResetPwd =  React.lazy(() => import( "@/pages/personal/ResetPwd"));





import ERR_404 from "@/pages/error/404";
import ERR_403 from "@/pages/error/403";

const Error = () => <div>error</div>
const Home = () => <div>Home</div>
const Test = () => <div>Test</div>

const buildMenuRoute=(path,IndexPage, DetailPage)=>{
    let route = {
        path,
        children:[{index:true, element: <React.Suspense fallback={<Spin />}><IndexPage/></React.Suspense>}, ]
    };
    if(DetailPage){
        route.children[1] =  {path:"detail/*", element: <React.Suspense fallback={<Spin />}><DetailPage/> </React.Suspense>}
    }

    return route;
}

const Routes = [
    {path: "/login", Component: LoginPage},
    {path: "", Component: LoginPage},


    {path:"/page",
        children: [
            {path: "home", Component: Home},

            {path:"shop",children: [
                    buildMenuRoute("list", ShopPage, ShopDetail),
                    buildMenuRoute("station", ShopStationPage, ShopStationDetail),
                ]
            },
            {path:"product",children: [
                    buildMenuRoute("wash", WashProductPage, WashProductDetail),
                    buildMenuRoute("recharge", RechargeProductPage, RechargeProductDetail),
                    buildMenuRoute("vip", VipProductPage, VipProductDetail),
                ]
            },


            {path:"user",children: [
                    buildMenuRoute("shopAccount", ShopAccountPage, ShopAccountDetail),
                    buildMenuRoute("userAccount", UserAccountPage, UserAccountDetail),
                    buildMenuRoute("userBalance", UserBalancePage, UserBalanceDetail),
                ]
            },
            {path:"order",children: [
                    buildMenuRoute("wash", WashOrderPage, WashOrderDetail),
                    buildMenuRoute("recharge", RechargeOrderPage, RechargeOrderDetail),
                    buildMenuRoute("vip", VipOrderPage, VipOrderDetail),
                ]
            },
            {path:"billing",children: [
                    buildMenuRoute("balance", ShopBalancePage, ShopBalanceDetail),
                    buildMenuRoute("withdrawal", ShopWithdrawalPage),
                    buildMenuRoute("balanceDetail", ShopBalanceDetailPage),

                ]
            },

            {path:"resource",children: [
                    buildMenuRoute("document", DocumentPage, DocumentDetail),
                    buildMenuRoute("ads", AdvertisementPage, AdvertisementDetail),

                ]
            },
            {path:"device",children: [
                    buildMenuRoute("internal", InternalDevicePage, InternalDeviceDetail),
                    buildMenuRoute("geek", GeekDevicePage, GeekDeviceDetail),

                ]
            },

            {path:"system",children: [
                    buildMenuRoute("menu", MenuPage, MenuDetail),
                    buildMenuRoute("config", ConfigPage, ConfigDetail),
                    buildMenuRoute("super", SuperPage),
                    buildMenuRoute("userSuper", UserSuperPage),
                ]
            },
            {path:"personal",children: [
                    buildMenuRoute("resetPwd", PersonalResetPwd)
                ]
            },

        ]
    },
    {path: "*", Component: ERR_404},
    {path: "/403", Component: ERR_403},
    /*  {path:"/demo", element:<Demo/>},
      {path:"/test", element:<Test/>}*/
]
export default Routes;